import React from 'react';
import { Link } from 'gatsby';
import Image from '../../../elements/Image';
import { Product } from '../../../../types/product';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => (
  <div className="tiles-item reveal-from-bottom">
    <div className="tiles-item-inner bg-primary-dark">
      <div className="features-tiles-item-header">
        <a href={product.url} className="features-tiles-item-image mb-16" target="_blank">
          <Image
            src={product.image.publicURL}
            alt={product.title}
            width={product.imageWidth}
            height={product.imageHeight}
          />
        </a>
      </div>
      <div className="features-tiles-item-content">
        <p className="m-0 text-sm">{product.description}</p>
      </div>
    </div>
  </div>
);

export default ProductCard;
