import React from 'react';
import { graphql } from 'gatsby';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import { SectionTilesTypesProps } from '../../utils/SectionProps';
import { AllJsonData } from '../../types/graphql';
import { Product } from '../../types/product';
import Products from '../../components/sections/products/Products';

interface ProductsProps extends SectionTilesTypesProps, AllJsonData<Product, { products: [] }> {}

const ProductsPage: React.FC<ProductsProps> = ({ data }) => {
  const products = data?.products.nodes || [];
  return (
    <LayoutDefault>
      <PageMetaData title="Products" />
      <Products products={products} />
    </LayoutDefault>
  );
};

export default ProductsPage;

export const query = graphql`
  query ProductsPage {
    products: allJson(filter: { contentName: { eq: "products" } }, sort: { fields: order }) {
      nodes {
        id
        title
        description
        image {
          publicURL
        }
        imageWidth
        imageHeight
        url
      }
    }
  }
`;
