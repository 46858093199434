import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import { Product } from '../../../types/product';
import { AllJsonData } from '../../../types/graphql';
import SectionHeader, { SectionHeaderTag } from '../partials/SectionHeader';
import ProductCard from './partials/ProductCard';
import Button from '../../elements/Button';

interface ProductsProps extends SectionTilesTypesProps, AllJsonData<Product, { products: [] }> {
  className?: string;
  products: Product[];
}

const defaultProps: ProductsProps = {
  products: [],
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
};

const Products: React.FC<ProductsProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  products,
  ...props
}) => {
  const outerClasses = classNames(
    'products section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  return (
    <section id="products-section" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag={SectionHeaderTag.H1} title="Atlassian Tools" className="center-content">
            <div className="center-content">
              <p className="mb-16">
                TeamBit is a Platinum Solution Partner of a well known company{' '}
                <a href="https://www.atlassian.com/" target="_blank">
                  Atlassian
                </a>{' '}
                that provides tools to help every team to unleash their full potential. All TeamBit services and
                solutions are based on the Atlassian suite - configuration, development, consultancy and support - so that
                you can experience the benefits of those in a full capacity.
              </p>
              <p className="mb-0">Check below for more details about each Atlassian product.</p>
            </div>
          </SectionHeader>
          <div className={tilesClasses}>
            {products?.map((product, i) => (
              <ProductCard product={product} key={i} />
            ))}
          </div>
          <div className="center-content">
            <div className="spacer-64 spacer-32-mobile" />
            <div className="container-xs reveal-from-bottom">
              <p className="text-white">
                Would you like to know more about any of above mentioned awesome Atlassian products? Request a demo
                below.
              </p>
              <Button
                tag="a"
                color="primary"
                wideMobile
                target="_blank"
                href="https://calendly.com/teambit-atlassian/demo"
              >
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Products.defaultProps = defaultProps;

export default Products;
